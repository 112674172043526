import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getTeachingList(data) {
  return request({
    url: '/backend/web/teaching/index',
    method: GET,
    params: data
  });
}

export function delTeaching(data) {
  return request({
    url: '/backend/web/teaching/delete',
    method: POST,
    data
  });
}

export function batDelTeaching(data) {
  return request({
    url: '/backend/web/teaching/batch-delete',
    method: POST,
    data
  });
}

export function distributeTeaching(data) {
  return request({
    url: '/backend/web/teaching/distribute',
    method: POST,
    data
  });
}

export function createTeaching(data) {
  return request({
    url: '/backend/web/teaching/create',
    method: POST,
    data
  });
}

export function getTeachingDetail(data) {
  return request({
    url: '/backend/web/teaching/detail',
    method: GET,
    params: data
  });
}

export function updateTeaching(data) {
  return request({
    url: '/backend/web/teaching/update',
    method: POST,
    data
  });
}